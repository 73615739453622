<template>
  <div class="game-item" :class="{ maintain: item.maintain }" @click="onClickGame()">
    <div class="item game-item__wrapper">
      <BaseImg
        class="game-item__wrapper--image"
        :src="item.img"
        :alt="item.name"
        :border-radius="`${radius ? radius : '16'}`"
        lazy
        :wait-lazy="waitLazy"
        :end-lazy="endLazy"
        @load="onLoadImage"
      />
    </div>
    <div v-if="jackpotNumber && !hiddenJacPot && !isBotChromeLighthouse" class="game-item__wrapper--jackpot">
      <div class="jackpot">
        <AnimateCountUp
          v-if="jackpotNumber !== 0"
          :key="jackpotNumber"
          :number="jackpotNumber"
          :show-coin="showCoin"
          :class="[osType]"
        />
      </div>
    </div>
    <div v-if="isPlayNow">
      <div class="game-item__wrapper--popup" :class="{ 'game-item__wrapper--custom_popup': jackpotNumber && !hiddenJacPot }">
        <div class="inner">
          <button>{{ textPlay }}</button>
          <p v-if="item?.is_demo" @click.stop.prevent="onClickGame(true)">Chơi thử</p>
        </div>
      </div>
      <div v-if="showName" class="game-item__wrapper--game-name">{{ item.name }}</div>
    </div>
    <BaseImg v-if="item.maintain" class="game-item__tags" :src="getThumbImage(item)" />
    <BaseImg
      v-else-if="getThumbImage(item) && showTag"
      class="game-item__tags"
      :class="[item?.tags?.name?.toLowerCase()]"
      :src="getThumbImage(item)"
      :alt="`tags ${item.tags}`"
    />
  </div>
</template>

<script setup lang="ts">
import debounce from 'lodash/debounce'
import { storeToRefs } from 'pinia'
import { useAppStore } from '@/store/app'
import useNavigationGame from '~/composables/useNavigationGame'
import AnimateCountUp from '~/components/common/animate-count-up.vue'

import { IGameItem } from '~/types/common'
import { PATH_IMAGES_ICON } from '~/constants/path'
const { $pinia, $device } = useNuxtApp()
const store = useAppStore($pinia)
const { jackpot } = storeToRefs(store)
const isLoadedImage = ref(false)
const osType = ref()
const { navigationCheckLoggedInAndOpenGame } = useNavigationGame()
const { isBotChromeLighthouse } = useAppStore()
const props = withDefaults(
  defineProps<{
    item: IGameItem
    showCoin?: boolean
    showName?: boolean
    isPlayNow?: boolean
    autoWidth?: boolean
    autoHeight?: boolean
    showTag?: boolean,
    radius?: string
    textPlay?: string
    isTryToPlay?: boolean
    hiddenJacPot?: boolean
    waitLazy?: boolean
    endLazy?: boolean
  }>(),
  {
    showCoin: true,
    showName: false,
    isPlayNow: true,
    autoWidth: false,
    autoHeight: false,
    showTag: true,
    radius: '12 ',
    textPlay: 'Chơi Ngay',
    isTryToPlay: true,
    hiddenJacPot: false,
    waitLazy: false,
    endLazy: false
  }
)

const jackpotNumber = computed<number>(() => {
  const keyJackpot: string = props.item.partner_game_id
  const value: Record<string, number> = jackpot.value
  return value[keyJackpot] ?? 0
})
const onLoadImage = () => {
  isLoadedImage.value = true
}
const onClickGame = debounce((isTryToPlay?: boolean) => {
  navigationCheckLoggedInAndOpenGame(props.item, false, isTryToPlay)
}, 50)
const getThumbImage = (item: IGameItem) => {
  if (item?.tags) {
    return item?.tags.img
  }
  return ''
}
onMounted(() => {
  osType.value = $device.isDesktop ? 'desktop' : $device.isIos ? 'ios' : 'android'
})
</script>

<style lang="scss" scoped src="public/assets/scss/components/common/game-item.scss"></style>
